import axios from 'axios'
import Vue from 'vue'
import qs from 'querystring'



import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import router from '@/router'
import Store from '@/store'


axios.defaults.withCredentials = true;

var base_url = 'https://www.jiayiliang.cn'
if (process.env.NODE_ENV === 'development') {
    base_url = 'http://192.168.3.15:8080';
}

export const BASE_URL = base_url;

const CONTENT_TYPE_FORM = "application/x-www-form-urlencoded";

Vue.$log.debug('BASE_URL:',BASE_URL);

const service = axios.create({
    timeout: 5000,
    baseURL: BASE_URL,
    method: 'GET',
})

// function signData(data){
//     Vue.$log.debug('befor params:',data);
//     var timestamp = new Date().getTime();
//     timestamp = parseInt(timestamp / 1000);
//     data['timestamp'] = ''+timestamp;
//     var sign = getSign(data);
//     data['sign'] = sign;
//     Vue.$log.debug('params:',data);
// }

//interceptors of request 
service.interceptors.request.use(
    config => {
        if (!config.mute) {
            NProgress.start()
        }
        config.headers['Content-Type'] = CONTENT_TYPE_FORM
        if(config.data){
            config.data = qs.stringify(config.data);
        }
        return config 
    },
    error => {
        NProgress.remove()
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        NProgress.done()
        Vue.$log.debug('response : ',response);
        const respData = response.data;
        if(response.status == 200){
            if(respData.code === 'OK'){
                return respData;
            }else{
                if(respData.code === 'account.not.logined'){
                    //进行用户登录状态清除
                    Store.clearUserLogin();
                    router.push({name: 'login'})
                }
                return Promise.reject(respData)
            }
        }else{
            return Promise.reject(respData);
        }   
    },
    error => {
        NProgress.done()
        
        if(axios.isCancel(error)){
            Vue.$log.debug("Canceled uploading by user")
            return Promise.reject(error)
        }
        Vue.$log.error('Response failed ',error)

        const response = error.response
        const status = response ? response.status : -1
        Vue.$log.error('Response status ',status)

        const data = response ? response.data : null
        if(data){
            Vue.$log.error('Business response status',data.status)
            error.message = data.message ;
            Vue.$log.debug(error)
            return Promise.reject(error)
        }else{
            error.message = "请求异常";
            Vue.$log.debug(error)
            return Promise.reject(error);
            
        }
    }
)

export default service