import service from '../util/service'
import axios from 'axios';
import { BASE_URL } from '../util/service';
import qs from 'querystring';

export const PATH_LOGIN = "/liang/backend/login/";
const PATH_LOGOUT = "/liang/backend/logout/";

const PATH_MERCHANT = "/liang/merchant/list/";
const PATH_ORDERNUM_ADD = "/liang/merchant/ordernum/add/";

const PATH_ACTIVECODE_CREATE = "/liang/activecode/create/";

const PATH_ORDER_LIST = "/liang/order/list/";
const PATH_ORDER_ADD = "/liang/order/add/";
export const PATH_ORDER_BULK_ADD = "/liang/order/bulk/add/";

const PATH_MERCHANT_ORDER_REMAINNUM = "/liang/order/remain/num/";
const PATh_MERCHANT_ORDER_LIST = "liang/merchant/order/list/";

const api = {}

api.login = (mobile, password) => {

    return service({
        url: PATH_LOGIN,
        data: {
            mobile,
            password
        },
        method: 'POST'
    })
}

api.logout = () => {
    return service({
        method: 'POST',
        url: PATH_LOGOUT
    });
}

api.merchantList = () => {
    return service({
        url: PATH_MERCHANT,
    })
}

api.orderAdd = (order_num, merchant_id) => {
    return service({
        url: PATH_ORDERNUM_ADD,
        data: {
            order_num,
            merchant_id
        },
        method: 'POST'
    });
}

api.activecodeCreate = (amount, merchant_id, expired_day, effect_day) => {
    return axios({
        url: BASE_URL + PATH_ACTIVECODE_CREATE,
        method: 'POST',
        responseType: 'blob',
        data: qs.stringify({
            amount,
            merchant_id,
            expired_day,
            effect_day
        })
    });
}

api.ordernumAdd = (order_num, merchant_id) => {
    return service({
        url: PATH_ORDERNUM_ADD,
        method: 'POST',
        data: {
            order_num,
            merchant_id
        }
    })
}

api.orderList = (page_size, page) => {
    return service({
        url: PATH_ORDER_LIST,
        params: {
            page_size,
            page
        }
    });
}

api.orderAdd = (order_no) => {
    return service({
        url: PATH_ORDER_ADD,
        method: 'POST',
        data: {
            order_no
        }
    });
}

api.merchantOrderRemainNum = () => {
    return service({
        url: PATH_MERCHANT_ORDER_REMAINNUM
    })
}

api.uploadOrderFile = (file) => {
    let formData = new FormData();
    formData.append('file', file)
    return axios.post(BASE_URL + PATH_ORDER_BULK_ADD, formData, {
        'Content-Type': 'multipart/form-data'
    });
}


api.branchOrderList = (merchant_id,page,page_size) => {
    return service({
        url: PATh_MERCHANT_ORDER_LIST,
        method: 'GET',
        params: {
            merchant_id,
            page,
            page_size
        }
    });
}


export default api
